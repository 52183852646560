// Checkout
export const API_CHECKOUT_CREDITS = '/checkout/credits'
export const API_CHECKOUT_MEMBERSHIP = '/checkout/membership'
export const API_CHECKOUT_SUCCESS = '/checkout/success'

// Init
export const API_INIT = '/init/app'

// Auth
export const API_AUTH_LOGIN = '/auth/login'

// Messages
export const API_MESSAGES = '/messages'
export const API_MESSAGES_INBOX = '/messages/inbox'
export const API_MESSAGES_POST = '/messages/post'
export const API_MESSAGES_READ = '/messages/read'

// Owners
export const API_OWNERS = '/owners'

// Stripe
export const API_STRIPE_CHECKOUT = '/stripe/checkout'

// Support
export const API_SUPPORT = '/support'

// Transactions
export const API_TRANSACTIONS = '/transactions'

// User
export const API_USER = '/user'
export const API_USER_DEACTIVATE = '/user/deactivate'
export const API_USER_LOGIN = '/user/login'
export const API_USER_MEDIA = '/user/media'
export const API_USER_REGISTER = '/user/register'
export const API_USER_UPDATE = '/user/update'
export const API_USER_FORGOT_PASSWORD = '/user/forgot-password'
export const API_USER_FORGOT_PASSWORD_RESET = '/user/forgot-password/reset'
export const API_USER_FORGOT_PASSWORD_VALIDATE = '/user/forgot-password/validate'

// Users
export const API_USERS = '/users'

// Content
export const API_CONTENT = '/content'